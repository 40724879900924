class IndustryModel {
    constructor(id, name, createdAt, updatedAt) {
      this.id = id || '';
      this.name = name || '';
      this.createdAt = createdAt || new Date().toISOString();
      this.updatedAt = updatedAt || new Date().toISOString();
    }
  }
  
  export default IndustryModel;
  