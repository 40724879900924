import IndustryReportItem from './industry-report-item';

class IndustryReportModel {
  constructor(
    industryId = '',
    industryName = '',
    report = []
  ) {
    this.industryId = industryId;
    this.industryName = industryName;
    this.report = report;
  }

  static fromJson(json) {
    const reportItems = json.report.map(item => IndustryReportItem.fromJson(item));
    return new IndustryReportModel(
      json.industryId,
      json.industryName,
      reportItems
    );
  }
}

export default IndustryReportModel;
