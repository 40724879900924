class IndustryReportItem {
  constructor(
    companyName = "",
    companyId = "",
    media = {
      interval: [],
    },
    youtube = {
      interval: [],
    },
    facebook = {
      interval: [],
    },
    instagram = {
      interval: [],
    }
  ) {
    this.companyName = companyName;
    this.companyId = companyId;
    this.media = media;
    this.youtube = youtube;
    this.facebook = facebook;
    this.totalPosts = 0;
    this.totalReactions = 0;
    this.totalComments = 0;
    this.totalShares = 0;
    this.totalStats = 0;
    this.youtubeLikes = 0;
    this.youtubeViews = 0;
    this.youtubeComments = 0;
    this.youtubeVideos = 0;
    this.youtubeSubs = 0;
    this.youtubeEngagement = 0;
    this.instaPosts = 0;
    this.instaReactions = 0;
    this.instaShares = 0;
    this.instaComments = 0;
    this.instaTotals = 0;
    this.instagram = instagram;
    this.calculateTotals();
  }

  static fromJson(json) {
    return new IndustryReportItem(
      json.companyName,
      json.companyId,
      json.media,
      json.youtube,
      json.facebook,
      json.instagram
    );
  }

  calculateTotals() {
    this.totalPosts = this.calculateTotal("articles");
    this.totalReactions = this.calculateTotal("likes");
    this.totalComments = this.calculateTotal("comments");
    this.totalShares = this.calculateTotal("shares");
    this.totalStats = this.totalReactions + this.totalComments + this.totalShares;
    this.calculateYoutubeTotals();
    this.instaPosts = this.calculateInstagramTotal("posts");
    this.instaReactions = this.calculateInstagramTotal("reactions");
    this.instaComments = this.calculateInstagramTotal("comments");
    this.instaShares = this.calculateInstagramTotal("shares");
    this.instaTotals = this.calculateInstagramTotal("total");
  }

  calculateTotal(key) {
    return this.facebook.interval.reduce((acc, item) => acc + item[key], 0);
  }
  calculateInstagramTotal(key) {
    return this.instagram.interval.reduce((acc, item) => acc + item[key], 0);
  }
  calculateYoutubeTotals() {
    const interval = this.youtube.interval;

    if (interval && interval.length > 1) {
      const first = interval[0];
      const last = interval[interval.length - 1];

      this.youtubeLikes = last.likes - first.likes;
      this.youtubeViews = last.views - first.views;
      this.youtubeComments = last.comments - first.comments;
      this.youtubeVideos = last.videos - first.videos;
      this.youtubeSubs = last.subscribers;
      this.youtubeTotals =
        this.youtubeLikes +
        this.youtubeComments +
        this.youtubeVideos +
        this.youtubeViews;
      const engagementSum =
        this.youtubeViews + this.youtubeComments + this.youtubeLikes;

      if (engagementSum === 0) {
        this.youtubeEngagement = 0;
      } else {
        this.youtubeEngagement = engagementSum / this.youtubeSubs;
      }
    } else {
      this.youtubeLikes = 0;
      this.youtubeViews = 0;
      this.youtubeComments = 0;
      this.youtubeVideos = 0;
      this.youtubeSubs = 0;
      this.youtubeTotals = 0;
      this.youtubeEngagement = 0;
    }
  }
}

export default IndustryReportItem;
