import Vuex from 'vuex';
import { auth, firestore } from '../services/firebase.js';
import industryList from './modules/industryList';
import industryReport from './modules/industryReport';

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    async login({ commit }, { email, password }) {
      try {
        const userCredential = await auth.signInWithEmailAndPassword(email, password);
        const user = userCredential.user;

        commit('setUser', user);
      } catch (error) {
        console.error('Login error:', error.message);
        // Handle login error (show error message, etc.)
      }
    },
    async logout({ commit }) {
      try {
        // Sign out the user
        await auth.signOut();

        // Clear user data in the store
        commit('clearUser');

        // Handle successful logout (redirect, etc.)
      } catch (error) {
        console.error('Logout error:', error.message);
        // Handle logout error (show error message, etc.)
      }
    },
    async register({ commit }, { email, password, username, tel, company }) {
      try {
        const { user } = await auth.createUserWithEmailAndPassword(email, password);

        await firestore.collection('users').doc(user.uid).set({
          displayName: username,
          telephone: tel,
          company: company
        });

        const regions = ['mk', 'hr', 'al', 'rs', 'bg', 'ks', 'ba'];
        for (const region of regions) {
          const regionDocRef = firestore.doc(`users/${user.uid}/regions/${region}`);
          await regionDocRef.set({ name: region });
          const keywordsRef = regionDocRef.collection('keywords');
          await keywordsRef.doc('_placeholder').set({ placeholder: true });
        }
        // Send verification email
        await user.sendEmailVerification();
        commit('setUser', user);
        return { success: true };
      } catch (error) {
        console.error('Error registering user:', error.message);
        return { success: false, error: error.message };
      }
    }
  },
  modules: {
    industryList,
    industryReport,
  },
});
