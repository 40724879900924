<template>
  <v-row
    v-if="userIsLoggedIn"
    :id="userIsLoggedIn ? 'menu' : ''"
    class="bg-white mt-0 rounded-xl"
  >
    <v-col id="menu-col" class="d-flex" cols="12">
      <v-seet>
        <v-list class="mt-2" v-if="userIsLoggedIn" density="compact" nav>
          <v-list-item
            prepend-icon="mdi-view-dashboard"
            title="Dashboard"
            value="dashboard"
            :to="{ path: `/${region}/dashboard` }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-rss"
            title="Sources"
            value="outlets"
            :to="{ path: `/${region}/sources` }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-forum"
            title="Articles"
            value="articles"
            :to="{ path: `/${region}/articles` }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-paperclip"
            title="Media Clipping"
            value="clipping"
            :to="{ path: `/${region}/clipping` }"
          ></v-list-item>
          <v-list-group value="facebook">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-facebook"
                title="Facebook"
              ></v-list-item>
            </template>
            <v-list-item
              prepend-icon="mdi mdi-table-of-contents"
              title="Overview"
              value="fboverview"
              :to="{ path: `/${region}/facebook` }"
            ></v-list-item>
            <v-list-item
              prepend-icon="mdi-fire"
              title="Popular"
              value="fbpopular"
              :to="{ path: `/${region}/fb-popular` }"
            ></v-list-item>
          </v-list-group>
          <v-list-item
            prepend-icon="mdi-format-list-bulleted"
            title="Similarity"
            value="similar"
            :to="{ path: `/${region}/similarity` }"
          ></v-list-item>
					<v-list-group value="Industry reports">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                prepend-icon="mdi-clipboard-text"
                title="Industry Reports"
              ></v-list-item>
            </template>
            <v-list-item
						v-for="industry in industries"
						:key="industry.id"
            :class="['text-capitalize', industry.active ? 'active-class' : 'inactive-class']"
              prepend-icon="mdi mdi-clipboard-text"
              :title="industry.name.toLowerCase()"
              :value="industry.id"
              :to="{ path: `/${region}/industry/${industry.id}` }"
            ></v-list-item>
          </v-list-group>
        </v-list>
      </v-seet>
      <v-seet>
        <v-divider v-show="userIsLoggedIn"></v-divider>
        <v-list v-if="userIsLoggedIn" density="compact" nav>
          <v-list-item
            prepend-icon="mdi-cog"
            title="Clipping Settings"
            value="keywords"
            :to="{ path: `/${region}/keywords` }"
          ></v-list-item>
          <v-list-item
            prepend-icon="mdi-account"
            title="Edit profile"
            value="profile"
            :to="{ path: '/profile' }"
          ></v-list-item>
        </v-list>
        <template v-if="userIsLoggedIn">
          <v-row justify="center">
            <v-col cols="12" class="text-center">
              <v-btn
                block
                outlined
                variant="outlined"
                color="primary"
                prepend-icon="mdi-logout"
                size="large"
                class="rounded-xl"
                @click="logout"
                >Logout</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </v-seet>
    </v-col>
  </v-row>
</template>
<script>
import { auth } from "../../services/firebase.js";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: ["hideItems", "region"],
  data() {
    return {
      userIsLoggedIn: false,
      userEmailVerified: false,
    };
  },
  methods: {
    async logout() {
      try {
        this.$store.dispatch("logout");
        this.$router.push("/login");
      } catch (error) {
        console.error("Error during logout:", error.message);
      }
    },
    ...mapActions("industryList", ["fetchIndustries"]),
  },
  computed: {
    userAuthenticated() {
      return this.userIsLoggedIn && this.userEmailVerified;
    },
    ...mapState("industryList", ["industries"]),
    ...mapGetters("industryList", ["industries"]),
  },
  created() {    
    auth.onAuthStateChanged((user) => {
      this.userIsLoggedIn = user !== null;
      this.userEmailVerified = user ? user.emailVerified : false;
    });
    this.fetchIndustries();
  },
};
</script>

<style scoped>
#menu {
  min-height: 80vh;
}
#menu-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}
</style>
