import axios from 'axios';
import { API_BASE_URL } from '@/config';
import IndustryReportModel from '@/models/industry/industry-report-model';

const state = {
  report: null,
  loading: false,
  error: null,
};

const mutations = {
  SET_REPORT(state, report) {
    state.report = report;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

const actions = {
  async fetchIndustryReport({ commit }, { region, reportId, queryParams }) {
    commit('SET_LOADING', true);
    try {
      const response = await axios.get(`${API_BASE_URL}/industries/${reportId}/report${queryParams}`, {
        headers: {
          'X-Language': region,
        }
      });
      const report = IndustryReportModel.fromJson(response.data);
      commit('SET_REPORT', report);
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

const getters = {
  report: state => state.report,
  isLoading: state => state.loading,
  error: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
