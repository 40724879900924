// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBVS6M5x6sIOl_qwjVmOo3UOLNoDZTNXLU",
    authDomain: "wiwianalytics.firebaseapp.com",
    projectId: "wiwianalytics",
    storageBucket: "wiwianalytics.appspot.com",
    messagingSenderId: "839239316535",
    appId: "1:839239316535:web:5d2d87a91d0a6a729f3bb2"
  };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();