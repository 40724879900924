import axios from 'axios';
import { API_BASE_URL } from '@/config';
import IndustryModel from '@/models/industry/industry-model';

const state = {
  industries: [],
  loading: false,
  error: null,
};

const mutations = {
  SET_INDUSTRIES(state, industries) {
    state.industries = industries;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

const actions = {
  async fetchIndustries({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await axios.get(`${API_BASE_URL}/industries`);
      const industries = Array.isArray(response.data.items) 
        ? response.data.items.map(ind => new IndustryModel(ind.id, ind.name, ind.createdAt, ind.updatedAt))
        : []; // Handle the case where response.data.items is not an array
      commit('SET_INDUSTRIES', industries);
    } catch (error) {
      commit('SET_ERROR', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

const getters = {
  industries: state => state.industries,
  isLoading: state => state.loading,
  error: state => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
