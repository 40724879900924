import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueApexCharts from "vue3-apexcharts";
import { loadFonts } from './plugins/webfontloader';
//import { auth } from './services/firebase.js';

loadFonts();

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueApexCharts);
  
router.isReady().then(() => {
  app.mount('#app');
});

