import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../services/firebase.js';

const routes = [
  {
    path: '/',
    redirect: '/mk' // Redirects to /mk when no region is specified
  },
  {
    path: '/login',
    name: 'login',
    props: false,
    meta: { requiresAuth: false },
    component: () => import('../views/user/LoginUser.vue')
  },
  {
    path: '/verify',
    name: 'verify',
    props: false,
    meta: { requiresAuth: true },
    component: () => import('../views/VerifyAccount.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    props: false,
    meta: { requiresAuth: true },
    component: () => import('../views/user/UserProfile.vue')
  },
  {
    path: '/:region/keywords',
    name: 'keywords',
    props: false,
    meta: { requiresAuth: true },
    component: () => import('../views/user/ManageKeywordGroups.vue')
  },
  {
    path: '/:region',
    name: 'homepage',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/Dashboard.vue')
  },

  {
    path: '/:region/dashboard',
    name: 'dashboard',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/:region/facebook',
    name: 'FacebookOverview',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/facebook/FacebookOverview.vue')
  },
  {
    path: '/:region//fb-popular',
    name: 'FbPopular',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/facebook/FbPopular.vue')
  },
  {
    path: '/:region//similarity',
    name: 'Similarity',
    props: true, 
    meta: { requiresAuth: true },
    component: () => import('../views/SimilarPost.vue')
  },
  {
    path: '/:region//articles',
    name: 'Articles',
    props: true, 
    meta: { requiresAuth: true },
    component: () => import('../views/ArticlesStats.vue')
  },
  {
    path: '/:region/sources',
    name: 'MediaOutlets',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/MainSources.vue')
  },
  {
    path: '/:region/clipping',
    name: 'MediaClipping',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/MediaClipping.vue')
  },
  {
    path: '/:region/industry/:reportId',
    name: 'IndustryReport',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('../views/IndustryReport.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    // Create a promise that resolves with the auth state
    new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe(); // Immediately unsubscribe once the user state is fetched
        resolve(user); // Resolve the promise with the user object
      }, reject);
    })
    .then(user => {
      const loggedIn = !!user;
      if (!loggedIn && to.path !== '/login') {
        next('/login'); // Redirect to login if not logged in and trying to access a protected route
      } if(loggedIn && !user.emailVerified && to.path !== '/verify') {
        next('/verify');
      }
      else {
        next(); // Proceed to the route
      }
    })
    .catch(error => {
      console.error("Error checking auth state: ", error);
      next('/login');
    });
});

export default router
